import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '@rotaready/frecl/build/Logo';

const Header = styled.header`
  z-index: 1;
  position: relative;
  background: rgba(2, 16, 51, 1);
`;

const Wrapper = styled.div`
  box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
`;

const LogoContainer = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80px;

  svg {
    width: auto;
    height: 30px;

    g {
      transition: fill .15s ease-in-out;
      fill: ${({ theme: { colors } }) => colors.white};
    }
  }
`;

const Minimalist = () => (
  <Header>
    <Wrapper>
      <LogoContainer>
        <Link to="/"><Logo /></Link>
      </LogoContainer>
    </Wrapper>
  </Header>
);

export default Minimalist;
